<template>
  <div class="google-reviews">
    <h2>Google Reviews</h2>
    <div v-if="reviews.length">
      <div v-for="review in reviews" :key="review.reviewId" class="review">
        <p>
          <strong>{{ review.authorName }}</strong>
        </p>
        <p>Note: {{ review.starRating }}/5</p>
        <p>{{ review.comment }}</p>
        <hr />
      </div>
    </div>
    <div v-else>
      <p>Chargement des avis...</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "GoogleReviews",
  data() {
    return {
      reviews: [],
      apiKey: "AIzaSyDnUkojMi06wTwAa6JIxT0m1l2D72ht3QY", // Remplacez par votre clé API
      placeId: "17666074896960243360", // Remplacez par l'ID de votre fiche Google My Business
    };
  },
  mounted() {
    this.fetchGoogleReviews();
  },
  methods: {
    async fetchGoogleReviews() {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/place/details/json?place_id=${this.placeId}&key=${this.apiKey}&fields=reviews`
        );

        console.log(response.data); // Debug la réponse complète ici

        if (
          response.data &&
          response.data.result &&
          response.data.result.reviews
        ) {
          this.reviews = response.data.result.reviews.map((review) => ({
            reviewId: review.time,
            authorName: review.author_name,
            starRating: review.rating,
            comment: review.text,
          }));
        } else {
          console.error("Aucun avis trouvé ou mauvaise réponse API.");
        }
      } catch (error) {
        // Vérifiez si l'erreur a une réponse
        if (error.response) {
          console.error(
            "Erreur HTTP :",
            error.response.status,
            error.response.data
          );
        } else if (error.request) {
          // L'erreur a été causée par une absence de réponse (problème réseau, API inaccessible)
          console.error("Aucune réponse de l'API : ", error.request);
        } else {
          // Une erreur est survenue lors de la configuration de la requête
          console.error(
            "Erreur lors de la configuration de la requête : ",
            error.message
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.google-reviews {
  max-width: 600px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
}

.review {
  background-color: #f9f9f9;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
