<template>
  <div class="container mt-5">
    <!--Section: Content-->
    <section class="formation">
      <!-- Section heading -->
      <h2 class="text-center font-weight-bold">Formations VTC</h2>
      <!-- Section description -->
      <div class="mx-auto w-responsive mb-5">
        <div class="container mb-5">
          <!--Section: Content-->
          <section class="text-center">
            <!-- Section heading -->
            <p
              class="text-center argument font-weight-bold dark-grey-text pb-2 my-4"
            >
              <strong>Prix formations vtc</strong>
            </p>
            <!-- Section description -->
            <p class="text-center w-responsive mx-auto mb-5">
              Découvrez nos <strong>formations VTC</strong>, conçues pour vous
              former aux exigences spécifiques du métier de chauffeur VTC et
              vous aider à exceller dans ce domaine en plein essor.
            </p>

            <!-- Grid row -->
            <div class="row">
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="aqua-gradient rounded-top">
                    <h3 class="option">INITIALE</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body">
                    <p class="price">1350<span>€</span></p>
                    <p class="priceBis">+233€ frais examen cma</p>
                    <ul>
                      <li>
                        <p>3 semaines</p>
                      </li>
                      <li>
                        <p>Lundi au Vendredi</p>
                      </li>
                      <li>
                        <p>Éligible CPF</p>
                      </li>
                      <li>
                        <p>Paiement en 3x sans frais</p>
                      </li>
                      <li>
                        <p>3 séances<br />de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-vtc-initiale">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="aqua-gradient rounded-top">
                    <h3 class="option">INITIALE-soir</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body">
                    <p class="price">1350<span>€</span></p>
                    <p class="priceBis">+233€ frais examen cma</p>
                    <ul>
                      <li>
                        <p>4 semaines</p>
                      </li>
                      <li>
                        <p>Lundi au Vendredi</p>
                      </li>
                      <li>
                        <p>Éligible CPF</p>
                      </li>
                      <li>
                        <p>Paiement en 3x sans frais</p>
                      </li>
                      <li>
                        <p>3 séances<br />de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-vtc-initiale">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-12 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="aqua-gradient rounded-top">
                    <h3 class="option">INITIALE-accéléré</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body">
                    <p class="price">1350<span>€</span></p>
                    <p class="priceBis">+233€ frais examen cma</p>
                    <ul>
                      <li>
                        <p>1 semaines</p>
                      </li>
                      <li>
                        <p>Lundi au Vendredi</p>
                      </li>
                      <li>
                        <p>Éligible CPF</p>
                      </li>
                      <li>
                        <p>Paiement en 3x sans frais</p>
                      </li>
                      <li>
                        <p>3 séances<br />de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-vtc-initiale">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Grid row -->
            <div class="row justify-content-center">
              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-6 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="peach-gradient rounded-top">
                    <h3 class="option">CONTINUE</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body striped orange-striped card-background">
                    <p class="price">170<span>€</span></p>
                    <ul>
                      <li>
                        <p>14h / 2 jours</p>
                      </li>
                      <li>
                        <p>Disponibilté<br />à convenir</p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-vtc-continue">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <!-- Features -->
                </div>
                <!-- Pricing card -->
              </div>
              <!-- Grid column -->

              <!-- Grid column -->
              <div class="price-card col-lg-4 col-md-6 mb-4 fall">
                <!-- Pricing card -->
                <div class="card pricing-card white-text">
                  <!-- Price -->
                  <div class="purple-gradient rounded-top">
                    <h3 class="option">PRATIQUE SEULE</h3>
                  </div>

                  <!-- Features -->
                  <div class="card-body striped purple-striped card-background">
                    <p class="price">390<span>€</span></p>
                    <ul>
                      <li>
                        <p>3 séances de conduite</p>
                      </li>
                      <li>
                        <p>
                          Véhicule mis à disposition<br />le jour de l'examen
                        </p>
                      </li>
                    </ul>
                    <div class="card-footer">
                      <router-link to="/formation-vtc-pratique-seule">
                        <button
                          name="button"
                          class="mb-3 mt-3 btn purple-gradient btn-rounded"
                        >
                          Détails &#187;
                        </button>
                      </router-link>
                    </div>
                  </div>
                  <!-- Features -->
                </div>
                <!-- Pricing card -->
              </div>
              <!-- Grid column -->
            </div>
          </section>
          <!--Section: Content-->
        </div>
      </div>
      <div class="hr-d"></div>
      <CallToContact
        title="Contactez nous"
        subTitle="L'equipe pédagogique de l'institut taxi s'occupe de vos démarches administratives."
      />
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
  </div>
  <section class="container-fluid double-block">
    <div class="double-block__title">
      <h2><span class="brand-pseudo">ITTI</span><br />Ecole VTC</h2>
    </div>
    <div class="dark-grey-text double-block__articles">
      <div class="row align-items-center article">
        <div class="col-lg-5 slide-left text-center">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
            <img
              src="@/assets/pictures/vtc-course.webp"
              alt="a-car-in-paris"
              class="img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-lg-7 slide-right">
          <h4 class="mb-3">
            Formations VTC :<br />
            Un tremplin vers une carrière polyvalente et passionnante
          </h4>
          <p>
            Découvrez notre Institut Taxi, votre partenaire de confiance pour
            des formations de qualité dans le domaine du VTC. Nous vous
            proposons une formation initiale complète qui vous permettra
            d'acquérir les compétences essentielles pour devenir un chauffeur
            VTC professionnel et accompli. Notre programme couvre divers aspects
            tels que la réglementation, la sécurité routière, les techniques de
            conduite défensive, la gestion des itinéraires, ainsi que les
            notions fondamentales du service client. Vous serez formé par des
            professionnels expérimentés qui vous guideront tout au long du
            processus d'apprentissage et vous prépareront efficacement pour les
            examens pratiques et théoriques. De plus, nos formations sont
            éligibles au CPF.
          </p>
        </div>
      </div>
      <div class="hr-d my-5"></div>
      <div class="row align-items-center article__left">
        <div class="col-lg-5 order-lg-2 slide-right text-center">
          <div class="view overlay rounded z-depth-2 mb-lg-0 mb-4">
            <img
              src="@/assets/pictures/sunny.webp"
              alt="rising-sun"
              class="img-fluid"
              loading="lazy"
            />
          </div>
        </div>
        <div class="col-lg-7 order-lg-1 slide-left">
          <h4 class="mb-3">
            Cours Accélérés et Cours du Soir :<br />
            Flexibilité pour concrétiser vos ambitions
          </h4>
          <p>
            Chez Institut Taxi, nous comprenons que vous avez peut-être des
            contraintes de temps et que vous souhaitez obtenir rapidement votre
            carte professionnelle VTC. C'est pourquoi nous proposons des cours
            accélérés qui vous permettent d'acquérir toutes les compétences
            nécessaires dans un laps de temps réduit. Ces formations intensives
            vous offrent la possibilité d'obtenir votre carte VTC plus
            rapidement, tout en bénéficiant d'un enseignement de qualité. Par
            ailleurs, si vous avez des engagements professionnels ou personnels
            en journée, nos cours du soir sont conçus pour s'adapter à votre
            emploi du temps chargé. Vous pourrez ainsi suivre la formation à des
            horaires flexibles, tout en poursuivant vos autres activités. De
            plus, nous acceptons le CPF et proposons un paiement échelonné en 3
            fois sans frais pour faciliter l'accès à nos formations. Que vous
            soyez en reconversion professionnelle ou que vous souhaitiez élargir
            vos compétences, notre Institut Taxi est là pour vous accompagner
            tout au long de votre parcours.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CallToContact from "@/components/plugins/CallToContact.vue";
export default {
  name: "ServicesVtc",
  components: { CallToContact },
  mounted() {
    import("@/assets/globalJs/global.js").then((module) => {
      const { observeElements } = module;
      observeElements(".slide-right", ".slide-left", ".fall");
    });
  },
};
</script>

<style lang="scss" scoped>
.formation {
  & h2 {
    text-align: center;
    background-color: $color-primary;
    color: $color-secondary;
    padding: 10px;
  }
  & .argument {
    font-family: "cunia";
    font-size: 1.3em;
  }
  .price-card {
    & .card {
      color: $color-text-light;
      background: #000000; /* fallback for old browsers */
      background: -webkit-linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* Chrome 10-25, Safari 5.1-6 */
      background: linear-gradient(
        to bottom,
        #434343,
        #000000
      ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

      & h3 {
        background-color: $color-primary;
        font-family: "quantum";
        color: $color-text-light;
        padding: 10px;
      }
      & .price {
        text-align: center;
        font-size: 2em;
        margin-bottom: 0px;
        & span {
          color: $color-secondary;
        }
      }
      & .priceBis {
        text-align: center;
        font-size: small;
        margin-bottom: 20px;
      }
      & .card-body {
        padding: 0px;
        & ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          list-style-type: none;
          padding: 0px;
          & li,
          p {
            text-align: center;
          }
        }
      }
      & .card-footer {
        background-color: $color-secondary;
        padding: 0px;
      }
    }
  }
}
.double-block {
  @media screen and (min-width: 992px) {
    padding: 20px 25px;
    margin: 0px 50px;
  }
  &__title {
    text-align: center;
    margin: 70px 5px 50px;
    & h2 {
      color: $color-primary;
    }
    @media screen and (min-width: 992px) {
      margin: 100px 0px;
    }
  }
  &__articles {
    margin: 0px 5px;
    .article {
      @media screen and (min-width: 992px) {
        margin: 0px 40px;
      }
      & h4 {
        color: $color-secondary;
      }
      &__left {
        & h4 {
          color: $color-primary;
        }
      }
    }
  }
}
.slide-right.active {
  @include slide-right(1.2s);
}
.slide-left.active {
  @include slide-left(1.2s);
}
.fall.active {
  @include fall-down(1s);
}
</style>
